<template>
  <div class="container tech" data-view>
    <el-row type="flex" justify="space-between" align="middle" class="tech-row">
      <el-col :xs="24" :sm="12" class="tech-text">
        <div class="tech-img d-hide">
          <img
            src="@/assets/img/error.svg"
            alt=""
            data-copyright-source="https://storyset.com/illustration/404-error-page-not-found-with-people-connecting-a-plug/cuate"
            data-copyright-name="Storyset"
            data-copyright-owner="Storyset"
            data-copyright-profile="https://storyset.com/"
          />
        </div>
        <h1>Ой!</h1>
        <p class="p-3 bold">
          Что-то пошло не&nbsp;так, и&nbsp;мы&nbsp;это уже чиним
        </p>

        <p class="p-4">
          Приносим извинения за&nbsp;предоставленные неудобства
          и&nbsp;предлагаем:
        </p>
        <ul class="list">
          <li>
            Перейти на&nbsp;<router-link :to="{ name: 'Home' }"
              >главную страницу</router-link
            >
            сайта
          </li>
          <li>
            Обратиться с&nbsp;интересующим вас вопросом в&nbsp;поддержку сервиса
            по&nbsp;электронной почте.
          </li>
        </ul>
      </el-col>
      <el-col :xs="24" :sm="10" class="tech-img m-hide">
        <img
          src="@/assets/img/error.svg"
          alt=""
          data-copyright-source="https://storyset.com/illustration/404-error-page-not-found-with-people-connecting-a-plug/cuate"
          data-copyright-name="Storyset"
          data-copyright-owner="Storyset"
          data-copyright-profile="https://storyset.com/"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
const meta = {
  title: "Заявка не отправлена",
};

export default {
  metaInfo: {
    title: meta.title,
    meta: [
      {
        name: "robots",
        content: "none",
      },
      {
        name: "og:title",
        content: meta.title,
      },
    ],
  },
};
</script>
